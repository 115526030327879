import type { GtmEventProduct } from '~/types/tracking/trackingProduct';
import type { Ecommerce } from '~/types/tracking/ecommerce';
import { usePrepareForTracking } from '~/composables/tracking/usePrepareForTracking';
import type { EventNameType } from '~/types/tracking/eventNameType';

/**
 * Composable to manage tracking on a single product
 */

export function useProductTracking() {
    const gtm = useGtm();

    const { getLoginStatus } = usePrepareForTracking();

    const trackProduct = (eventName: EventNameType, ecommerce: Ecommerce) => {
        gtm?.trackEvent({ ecommerce: null });

        const event: GtmEventProduct = {
            event: eventName,
            ...getLoginStatus(),
            ecommerce: ecommerce,
        };

        gtm?.trackEvent(event);
    };

    return {
        trackProduct,
    };
}
